import {styled, useTheme} from '@mui/material/styles'
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import _ from 'lodash'
import {setLoading, setSnackbarMsg} from "../../../store/reducers/misc";
import {addMerchandise, editMerchandise, getMerchandise, getEticketEvents} from "../../../lib/request";
import Button from "@mui/material/Button";
import PanelHead from "../../../components/panel-head";
import {TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { updateEventDropdown } from '../../../store/reducers/event'

const StyledDiv = styled(Box)``

export default function PackageForm() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const {id} = useParams()
    const eventId = useSelector((state) => state.event.events)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {me, brandInFocus} = useSelector(state => state.profile)
    const eventsPayload = useSelector((state) => state.event.dropdown)

    const [form, setForm] = useState({
        title: '',
        image: '',
        url: '',
        isActive: true,
        eventIds: [],
        titleErr: '',
        imageErr: '',
        urlErr: '',
    })

    useEffect(() => {
        if (_.isEmpty(eventsPayload)) {
            getEticketEvents({ brandId: brandInFocus })
                .then((res) => {
                    dispatch(updateEventDropdown(res.data))
                })
                .catch(err => {
                    console.log(err.response.data.message)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsPayload])

    useEffect(() => {
        if(!_.isEmpty(id)) {
            dispatch(setLoading(true))
            getMerchandise(id)
                .then((res) => {
                    const formClone = _.cloneDeep(res.data)
                    formClone.eventIds = res.data?.EticketEvents.map((event) => event.id)
                    setForm(formClone)
                })
                .catch(err => {
                    if (err.response) {
                        dispatch(setSnackbarMsg(err.response.data.message))
                    }
                })
                .finally(() => {
                    dispatch(setLoading(false))
                })
        }
    }, [id])

    function changeForm(e) {
        const {name,value} = e.target
        const formClone = _.cloneDeep(form)
        formClone[name] = value

        formClone.titleErr = ''
        formClone.imageErr = ''
        formClone.urlErr = ''

        setForm(formClone)
    }

    function changeSwitch(e, v) {
        changeForm({target: {name: e.target.name, value: v}})
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setForm((prev) => ({
            ...prev,
            eventIds: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    function submit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.title))) {
            formClone.titleErr = 'Title is required'
            error = true
        }
        if (_.isEmpty(_.trim(form.image))) {
            formClone.imageErr = 'Image is required'
            error = true
        }
        if (_.isEmpty(_.trim(form.url))) {
            formClone.urlErr = 'Url is required'
            error = true
        }
        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        addMerchandise(formClone)
            .then(() => {
                dispatch(setSnackbarMsg('Merchandise created'))
                navigate(`/eticket`)
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }

    function updateSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        const formClone = _.cloneDeep(form)

        let error = false

        if (_.isEmpty(_.trim(form.title))) {
            formClone.titleErr = 'Title is required'
            error = true
        }
        if (_.isEmpty(_.trim(form.image))) {
            formClone.titleErr = 'Image is required'
            error = true
        }
        if (_.isEmpty(_.trim(form.url))) {
            formClone.titleErr = 'Url is required'
            error = true
        }

        if (error) {
            return setForm(formClone)
        }

        setForm(formClone)
        dispatch(setLoading(true))

        editMerchandise(id, formClone)
            .then((res) => {
                dispatch(setSnackbarMsg('Merchandise updated'))
                navigate(`/eticket`)
            })
            .catch(err => {
                if (err.response) {
                    dispatch(setSnackbarMsg(err.response.data.message))
                }
            })
            .finally(() => dispatch(setLoading(false)))
    }

    function addEvent() {

    }



    return (
        <StyledDiv>
            <PanelHead title={!_.isEmpty(id) ? `Update Merchandise Details` : `Create New Merchandise`}>
                <Link to={`/eticket`}>
                    <Button
                        fullWidth={isMobile}
                        variant={isMobile ? 'outlined' : 'text'}
                        color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
                        Back
                    </Button>
                </Link>
                {
                    !_.isEmpty(id) ? (
                        <>
                            {
                                !_.isEmpty(me) && (me.permission.includes('U')) && (
                                    <Button fullWidth={isMobile} variant="outlined" onClick={updateSubmit} sx={{mt: isMobile ? 1 : 0}}>
                                        Update
                                    </Button>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
                                Save
                            </Button>
                        </>
                    )
                }
            </PanelHead>
            <Box sx={{p: 4}}>
                <Box component="form" noValidate onSubmit={submit}>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={12} item>
                            <TextField
                                required
                                fullWidth
                                type="text"
                                margin="normal"
                                label="Title"
                                name="title"
                                InputLabelProps={{shrink: true}}
                                error={!_.isEmpty(form.titleErr)}
                                helperText={form.titleErr}
                                value={form.title}
                                onChange={changeForm}/>
                            <TextField
                                required
                                fullWidth
                                type="text"
                                margin="normal"
                                label="Image url"
                                name="image"
                                InputLabelProps={{shrink: true}}
                                error={!_.isEmpty(form.imageErr)}
                                helperText={form.imageErr}
                                value={form.image}
                                onChange={changeForm}/>
                            <TextField
                                required
                                fullWidth
                                type="text"
                                margin="normal"
                                label="Url"
                                name="url"
                                InputLabelProps={{shrink: true}}
                                error={!_.isEmpty(form.urlErr)}
                                helperText={form.urlErr}
                                value={form.url}
                                onChange={changeForm}/>
                        </Grid>
                        <Grid xs={12} md={6} item>
                            <FormGroup component="fieldset">
                                <FormControlLabel
                                    control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
                                    label="is available right now"/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            select
                            fullWidth
                            margin="normal"
                            label="Event Name"
                            InputLabelProps={{ shrink: true }}
                            name="eventIds"
                            helperText={'Can multi select events'}
                            value={form.eventIds}
                            onChange={handleChange}
                            SelectProps={{
                                multiple: true,
                            }}>
                            <MenuItem value="" disabled>
                                Please select
                            </MenuItem>
                            {
                                eventsPayload.map((event) => (
                                    <MenuItem key={event.id} value={event.id}>
                                        {event.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Box>
            </Box>


        </StyledDiv>
    )
}
