import React from 'react'
import {styled} from '@mui/material/styles'
import Box from "@mui/material/Box";
import _ from 'lodash'
import Alert from "@mui/material/Alert";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {useNavigate} from "react-router-dom";

const StyledDiv = styled(Box)``

export default function Tab7(props) {
    const navigate = useNavigate()
    const { event } = props
    const merchandise = event.Merchandise

    return (
        <StyledDiv>
            {
                _.isEmpty(merchandise) ? (
                    <Alert severity={'info'} sx={{m: 2}}>No available merchandise yet</Alert>
                ) : (
                    <TableContainer>
                        <Table sx={{minWidth: 650}}>
                            <TableHead className="tblHead">
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Title</TableCell>
                                    <TableCell align="left">Active?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    merchandise.map((row,index) => {
                                        return (
                                            <TableRow key={index}
                                                      hover
                                                      sx={{cursor: 'pointer'}}
                                                      onClick={() => navigate(`/eticket/merchandise/${row.id}`)}>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>{row.isActive ? 'Yes' : 'No'}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </StyledDiv>
    )
}
