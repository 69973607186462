import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import _ from 'lodash'
import {Link, useParams} from 'react-router-dom'

import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import PanelHead from '../../../components/panel-head'

import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {setEvents, setTabIndex} from '../../../store/reducers/event'
import {getEticketEventbyID, getEticketOrganizers} from '../../../lib/request'


import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
import Tab4 from './tab4'
import Tab5 from './tab5'
import Tab6 from './tab6'
import Tab7 from './tab7'

const StyleDiv = styled(Box)`
  ${({theme}) => {
    const mobileQuery = theme.breakpoints.down('md')

    return `
		.tblHead th {
			font-weight: bolder;
		}
	
		${mobileQuery} {
			.tab-content {
				max-width: 100vw;
			}
		}
	`
  }}`

export default function Eticket() {
	const dispatch = useDispatch()
	const {id} = useParams()
	const theme = useTheme()

	const {me} = useSelector(state => state.profile)
	const {tabIndex} = useSelector(state => state.event)
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const [event, setEvent] = useState({})
	const [organizerDropdown, setOrganizerDropdown] = useState([])
	const [beginSubmit, setBeginSubmit] = useState(false)

	useEffect(() => {
		if (!isNaN(id)) {
			queryEvent(id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	useEffect(() => {
		if (_.isEmpty(organizerDropdown)) {
			getEticketOrganizers()
				.then((res) => setOrganizerDropdown(res.data))
				.catch(err => {
					console.log(err.response.data.message)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizerDropdown])

	function queryEvent(eId) {
		dispatch(setLoading(true))
		getEticketEventbyID(eId)
			.then((res) => {
				setEvent(res.data)
				dispatch(setEvents(res.data.id))
			})
			.catch(err => {
				if (err.response) {
					const {data: {message}} = err.response
					dispatch(setSnackbarMsg(message))
					setEvent({err: 'Cannot found event information'})
				} else {
					setEvent({err: 'There is an error'})
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function changeTab(e, v) {
		dispatch(setTabIndex(v))
	}

	return (
		<StyleDiv>
			<PanelHead title={` ${event.title} - Event Details`}>
				<Link to={'/eticket'}>
					<Button
						fullWidth={isMobile}
						color={'inherit'}
						variant={isMobile ? 'outlined' : 'text'}
						sx={{mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0}}>
						Back
					</Button>
				</Link>
				{
					!_.isEmpty(me) && (me.permission.includes('U') && tabIndex === 0) && (
						<Button variant={'outlined'} fullWidth={isMobile} onClick={() => setBeginSubmit(true)}>
							Save
						</Button>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 1) && (
						<Link to={'/eticket/event/meta/create'}>
							<Button variant="outlined" fullWidth={isMobile}>
								Add Event Meta
							</Button>
						</Link>
					)
				}
				{
					!_.isEmpty(me) && (me.permission.includes('C') && tabIndex === 2) && (
						<Link to={'/eticket/package/create'}>
							<Button variant="outlined" fullWidth={isMobile}>
								Add Packages
							</Button>
						</Link>
					)
				}

			</PanelHead>
			<Box className={'tab-content'}>
				{
					_.isEmpty(event) && <Alert severity={'info'} sx={{m: 2}}>Loading events information. Please wait..</Alert>
				}
				{
					_.has(event, 'err') && <Alert severity={event.errT || 'error'} sx={{m: 2}}>{event.err}</Alert>
				}
				{
					!_.isEmpty(event) && !_.has(event, 'err') && (
						<>
							<Paper square variant={'outlined'}>
								<Tabs
									value={tabIndex}
									onChange={changeTab}
									variant="scrollable"
									scrollButtons="auto">
									<Tab label="Main"/>
									<Tab label="Additional Info"/>
									<Tab label="Packages"/>
									<Tab label="Tickets"/>
									<Tab label="Payments"/>
									<Tab label="Reports"/>
								</Tabs>
							</Paper>
							<Box role={'tabpanel'} hidden={tabIndex !== 0} sx={{p: 2}}>
								{
									tabIndex === 0 && (
										<Tab1
											organizerDropdown={organizerDropdown}
											event={event}
											beginSubmit={beginSubmit}
											setBeginSubmit={setBeginSubmit}
										/>
									)
								}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 1}>
								{tabIndex === 1 && <Tab2 event={event}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 2}>
								{tabIndex === 2 && <Tab3 event={event}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 3}>
								{tabIndex === 3 && <Tab4 event={event}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 4}>
								{tabIndex === 4 && <Tab5 event={event}/>}
							</Box>
							<Box role={'tabpanel'} hidden={tabIndex !== 5}>
								{tabIndex === 5 && <Tab6 event={event}/>}
							</Box>
						</>
					)
				}
			</Box>
		</StyleDiv>
	)
}
