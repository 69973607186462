import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import {
	addProfileSubscriptionPlan,
	deleteProfileSubscriptionPlan,
	editProfileSubscriptionPlan,
	getSubscriptions,
	getProfileUserPlanbyID,
	deleteProfileUserPlan, getAdditionalPackages
} from '../../../lib/request'

import { setLoading, setSnackbarMsg } from '../../../store/reducers/misc'
import DialogConfirmChangeUserSubscription from './dialog-confirm-change-user-subscription'
import DialogConfirmRemoveUserSubscription from './dialog-confirm-remove-user-subscription'

const StyledDiv = styled(Box)`
	${({ theme }) => `
    padding: ${theme.spacing(2)};    
    
    .btn-container {
    	text-align: right;
    }
	
  `}
`


export default function Tab4(props) {

	const { profile, queryProfile } = props
	const myProfile = profile.Profile[0]

	const { Subscription, Payments, id } = myProfile

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const dispatch = useDispatch()
	const { me, brandInFocus } = useSelector(state => state.profile)

	const [plans, setPlans] = useState([])
	const [addons, setAddons] = useState([])
	const [userplans, setUserPlans] = useState([])
	const [form, setForm] = useState({
		plansId: 'null',
		expiry: Subscription ? dayjs(Subscription.expiry) : dayjs().add(1, 'd'),
		notes: Subscription ? Subscription.notes : '',
		additionalPackageIds: Subscription ? Subscription?.AdditionalPackages?.map((a) => a.id) :[],
	})
	const [showConfirmDialog, setShowConfirmDialog] = useState(false)
	const [showConfirmRemoveDialog, setShowConfirmRemoveDialog] = useState(false)
	const [addingMode, setAddingMode] = useState(false)
	const [recurringStatus, setRecurringStatus] = useState(null)
	const [countDeletedAt, setCountDeletedAt] = useState(0)

	const handleDelete = (pid, id) => {
		dispatch(setLoading(true))

		deleteProfileUserPlan(id)
			.then(() => getProfileUserPlanbyID(pid)
				.then(response => setUserPlans(response.data))
				.catch(err => console.error(err)))
			.then(() => dispatch(setSnackbarMsg('User subscription plan removed')))
			.catch((err) => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => {
				dispatch(setLoading(false))
			})
	};

	const columns = [
		{ field: 'id', headerName: 'ID', width: 90 },
		{
			field: 'planName',
			headerName: 'Plan Name',
			width: 300,
			renderCell: (params) => {
				const planName = params.row.Subscriptionplans.name;
				return planName;
			},
		},
		{
			field: 'notes',
			headerName: 'Notes',
			width: 400,
		},

		{
			field: 'expiry',
			headerName: 'Expiry',
			width: 210,
		},
		{
			field: 'createdAt',
			headerName: 'createdAt',
			type: 'date',
			width: 210,
		},
		{
			field: 'deletedAt',
			headerName: 'deletedAt',
			type: 'date',
			width: 210,
		},
		{
			field: 'isCanceled',
			headerName: 'isCanceled',
			width: 150,
		},
		{
			field: 'delete',
			headerName: '',
			sortable: false,
			width: 150
		},

	];

	useEffect(() => {

		getSubscriptions({ unlimited: true, withSubscriber :false })
			.then(response => setPlans(response.data.rows))
			.catch(err => console.error(err))

		getAdditionalPackages({ unlimited: true, brandId: brandInFocus})
			.then(response => setAddons(response.data.rows))
			.catch(err => console.error(err))
		getProfileUserPlanbyID(id)
			.then(response => {
				setUserPlans(formatDates(response.data))
				let count = 0
				response.data.forEach(item => {
					if (_.isEmpty(item.deletedAt)) {
						count++
					}
				})
				setCountDeletedAt(count)
			})
			.catch(err => console.error(err))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {

		if (Subscription && !_.isEmpty(plans)) {
			const formClone = _.cloneDeep(form)
			formClone.plansId = Subscription.plansId
			setForm(formClone)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plans])

	useEffect(() => {

		if (!_.isEmpty(Subscription) && form.notes !== Subscription.notes) {
			const formClone = _.cloneDeep(form)
			formClone.notes = Subscription.notes
			formClone.expiry = dayjs(Subscription.expiry)
			setForm(formClone)
		}

		Payments.forEach(p => {
			if (p.Meta && !_.isEmpty(_.trim(p.Meta.token))) {
				setRecurringStatus(true)
				return null
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Subscription])

	useEffect(() => {

		const formClone = _.cloneDeep(form)
		if (Subscription && form.plansId !== Subscription.plansId && form.plansId !== 'null') {
			formClone.notes = `Plan assign manually by ${me.username}`
			formClone.additionalPackageIds =Subscription?.AdditionalPackages?.map((a) => a.id)
			setForm(formClone)
		} else if (addingMode) {
			const newPlan = _.find(plans, { id: form.plansId })
			formClone.additionalPackageIds = []
			if (newPlan) {
				const { intervals, isAnnually } = newPlan
				if (isAnnually) {
					formClone.expiry = dayjs().add(1, 'year').add(1, 'day')
				} else {
					formClone.expiry = dayjs().add(intervals, 'month').add(1, 'day')
				}
				setForm(formClone)
			}
		}

		setForm(formClone)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.plansId])

	function formatDates(data) {
		return data.map(item => {
			if(!_.isEmpty(item.createdAt)) {
				item.createdAt = dayjs(item.createdAt).format('DD-MMM-YYYY HH:mm:ss')
			}
			if(!_.isEmpty(item.expiry)) {
				item.expiry = dayjs(item.expiry).format('DD-MMM-YYYY')
			}
			if(!_.isEmpty(item.deletedAt)) {
				item.deletedAt = dayjs(item.deletedAt).format('DD-MMM-YYYY HH:mm:ss')
			}
			return item
		})
	}

	function manualAssign() {
		const formClone = _.cloneDeep(form)
		formClone.notes = `Admin ${me.username} manually assign plan for this user`
		setForm(formClone)

		setAddingMode(true)
	}

	function changeForm(e) {
		const { name, value } = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value
		setForm(formClone)
	}

	function changeExpiry(v) {
		const formClone = _.cloneDeep(form)
		formClone.expiry = v
		setForm(formClone)
	}

	function resetForm() {
		setForm({
			plansId: Subscription ? Subscription.plansId : 'null',
			expiry: Subscription ? dayjs(Subscription.expiry) : dayjs(),
			notes: Subscription ? Subscription.notes : ''
		})
	}

	const handleChange = (e) => {
		const { value } = e.target;
		setForm((prev) => ({
			...prev,
			additionalPackageIds: typeof value === 'string' ? value.split(',') : value,
		}));
	};

	function updateUserSubscription(e) {
		e.preventDefault()
		e.stopPropagation()

		if (!form.expiry.isValid() || form.expiry.isBefore(dayjs())) {
			return dispatch(setSnackbarMsg('Invalid date'))
		}
		if (form.plansId === 'null') {
			return dispatch(setSnackbarMsg('Invalid plan'))
		}

		dispatch(setLoading(true))

		const formClone = _.cloneDeep(form)

		if (_.isEmpty(formClone.notes)) {
			delete formClone.notes
		}

		formClone.expiry = formClone.expiry.toDate()

		let promises = null
		if (addingMode) {
			formClone.profileId = myProfile.id
			promises = addProfileSubscriptionPlan(formClone)
		} else {
			promises = editProfileSubscriptionPlan(myProfile.id, formClone)
		}


		promises
			.then(() => queryProfile(profile.id))
			.then(() => dispatch(setSnackbarMsg('User subscription plan updated')))
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => {
				setShowConfirmDialog(false)
				dispatch(setLoading(false))
			})
	}

	function confirmRemoveUserSubscription() {
		dispatch(setLoading(true))
		deleteProfileSubscriptionPlan(myProfile.id)
			.then(() => queryProfile(profile.id))
			.then(() => dispatch(setSnackbarMsg('User subscription plan removed')))
			.catch((err) => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => {
				setShowConfirmRemoveDialog(false)
				dispatch(setLoading(false))
			})
	}

	return (
		<StyledDiv>
			{
				_.isEmpty(Subscription) && !addingMode ? (
					<Box>
						<Alert severity={'info'}>Currently user not subscribing to any plan</Alert>
						{
							profile.isOptOut === false && (
								<Box className={'btn-container'} sx={{ pt: 2 }}>
									<Button variant={'outlined'} onClick={manualAssign}>Manually give user a plan</Button>
								</Box>
							)
						}
					</Box>
				) : (
					<Box>
						{
							recurringStatus === true && (
								<Alert severity={'success'}>User profile is on recurring subscription</Alert>
							)
						}
						{
							recurringStatus === false && (
								<Alert severity={'info'}>User profile cancel recurring subscription</Alert>
							)
						}
						{
							_.isNull(recurringStatus) && (
								<Alert severity={'info'}>User profile is not in recurring subscription</Alert>
							)
						}
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							{
								!_.isEmpty(plans) ? (
									<TextField
										fullWidth
										select
										margin={'normal'}
										label={'Current plan'}
										name={'plansId'}
										value={form.plansId}
										onChange={changeForm}
										InputLabelProps={{ shrink: true }}
										InputProps={{ readOnly: !/U/.test(me.permission) }}
									>
										<MenuItem value={'null'}>No plan</MenuItem>
										{
											plans.map((p, i) => {
												return <MenuItem key={i} value={p.id}>{p.name}</MenuItem>
											})
										}
									</TextField>
								) : (
									<TextField
										fullWidth
										margin={'normal'}
										label={'Current plan'}
										defaultValue={Subscription && Subscription.Subscriptionplans ? Subscription.Subscriptionplans.name : ''}
										InputLabelProps={{ shrink: true }}
										InputProps={{ readOnly: !/U/.test(me.permission) }}
										helperText={/U/.test(me.permission) ? 'You don\'t have access to subscription plan module. So you cannot edit this part.' : ''}
									/>
								)
							}
							{
								!_.isEmpty(addons) && (
								<TextField
									select
									fullWidth
									margin="normal"
									label="Additional Packages"
									InputLabelProps={{ shrink: true }}
									name="additionalPackageIds"
									helperText={'Can multi select additional package'}
									value={form.additionalPackageIds || []}
									onChange={handleChange}
									SelectProps={{
										multiple: true,
									}}>
									<MenuItem value="" disabled>
										Please select
									</MenuItem>
									{
										addons.map((addon) => (
											<MenuItem key={addon.id} value={addon.id}>
												{addon.name}
											</MenuItem>
										))
									}
								</TextField>
								)
							}
							{
								(!_.isEmpty(Subscription) && !_.isEmpty(Subscription.AdditionalPackages) ) && (

									<Typography variant="body2" sx={{ mb: 1 }}>
										<b>Additional Packages: </b> {Subscription.AdditionalPackages.map((p) => p.name).join(', ')}
									</Typography>
								)
							}
							<DatePicker
								disableMaskedInput
								label={'Expiry'}
								value={form.expiry}
								onChange={changeExpiry}
								readOnly={!/U/.test(me.permission)}
								inputFormat="DD MMMM YYYY"
								minDate={Subscription ? dayjs().subtract(1, 'd') : dayjs().add(1, 'd')}
								renderInput={(params) => <TextField fullWidth margin={'normal'} InputProps={{ readOnly: true }} {...params} />} />
							<TextField
								fullWidth
								multiline
								rows={3}
								margin={'normal'}
								label={'Extra notes'}
								name={'notes'}
								value={form.notes}
								onChange={changeForm}
								InputLabelProps={{ shrink: true }}
								InputProps={{ readOnly: !/U/.test(me.permission) }}
							/>
							{
								profile.isOptOut === false && (
									<Box className={'btn-container'} sx={{ pt: 2 }}>
										<Button
											fullWidth={isMobile}
											variant={isMobile ? 'outlined' : 'text'}
											sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0 }}
											color={'inherit'}
											onClick={resetForm}>
											Reset
										</Button>
										{
											Subscription && dayjs().isBefore(dayjs(Subscription.expiry)) && /D/.test(me.permission) && (
												<Button
													fullWidth={isMobile}
													variant={'contained'}
													color={'error'}
													sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0 }}
													onClick={() => setShowConfirmRemoveDialog(true)}>
													Remove user subscription
												</Button>
											)
										}
										{
											/U/.test(me.permission) && (
												<Button
													fullWidth={isMobile}
													variant={'outlined'}
													disabled={!form.expiry.isValid() || form.plansId === 'null'}
													onClick={() => setShowConfirmDialog(true)}>
													Save
												</Button>
											)
										}
										<Box sx={{ height: 390, width: '100%', py: '30px', textAlign: 'left' }}>
											<Typography variant="h5" sx={{ mb: 2 }}>
												List of User Subscription Plan
											</Typography>
											<DataGrid
												rows={userplans}
												columns={columns.map((column) => {
													if (column.field === 'delete') {
														return {
															...column,
															renderCell: (params) => (
																(me.permission.includes('D') && params.row.deletedAt == null && countDeletedAt > 1) && (
																	<Button
																		fullWidth
																		variant={'contained'}
																		color={'error'}
																		onClick={() => handleDelete(id, params.row.id)}>
																		Delete
																	</Button>
																)
															),
														};
													}
													return column;
												})}
												initialState={{
													pagination: {
														paginationModel: {
															pageSize: 10,
														},
													},
												}}
												pageSizeOptions={[5]}
												disableRowSelectionOnClick
											/>
										</Box>
									</Box>
								)
							}
						</LocalizationProvider>

						{
							showConfirmDialog && (
								<DialogConfirmChangeUserSubscription
									onClose={() => setShowConfirmDialog(false)}
									onConfirm={updateUserSubscription}
									plans={plans}
									before={Subscription}
									after={form}
									addons={addons}
								/>
							)
						}
						{
							showConfirmRemoveDialog && (
								<DialogConfirmRemoveUserSubscription
									onClose={() => setShowConfirmRemoveDialog(false)}
									onConfirm={confirmRemoveUserSubscription}
								/>
							)
						}
					</Box>
				)
			}
		</StyledDiv>
	)
}
